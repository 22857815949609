import * as React from "react";
import { useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import DCFast60 from "../assets/products/dc-fast-60.png";
import DCFast30 from "../assets/products/dc-fast-30.png";
import HN10 from "../assets/products/hn10.png";
import M2A from "../assets/products/m2a.png";
import M3W from "../assets/products/m3w.png";
import Box from "@mui/material/Box";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const styles = {
  container: {
    border: "2px solid #6BC9A3",
    backgroundColor: "#242424",
    padding: "20px",
    borderRadius: "7px",
  },
  heading: {
    textAlign: "center",
    color: "#6BC9A3",
    marginBottom: "20px",
    textTransform: "uppercase",
  },
  propertyWrapper: {
    color: "#b0b0b0",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  property: {
    color: "#b0b0b0",
  },
  value: {
    color: "#ffffff",
  },
};

const products: Record<
  number,
  {
    name: string;
    image: string;
    properties: { property: string; value: string }[];
  }
> = {
  0: {
    name: "RENOCH HN10 SERIES",
    image: HN10,
    properties: [
      { property: "Input", value: "230 VAC.50/60 H7" },
      { property: "Output voltage", value: "3.5 KW | 7 KW" },
      { property: "Output current", value: "16A | 32A" },
      {
        property: "Connector type",
        value: "ICE 621196-2, type 2 plug+5m charging cable",
      },
      {
        property: "Indicators",
        value: "1 LED indicator with multiple changing colors",
      },
      { property: "Length of charging cable", value: "5m" },
    ],
  },
  1: {
    name: "RENOCH M3W EN-SERIES",
    image: M3W,
    properties: [
      { property: "Input", value: "230 VAC,50/60 HZ" },
      { property: "Output voltage", value: "3.5 KW | 7 KW" },
      { property: "Output current", value: "16A | 32A" },
      {
        property: "Connector type",
        value: "IGE 621196-2, type 2 plug+5m charging cable",
      },
      { property: "Display", value: '4.3" LCD screen' },
      { property: "Length of charging cable", value: "5m" },
    ],
  },
  2: {
    name: "RENOCH M2A SERIES 1-PHASE PORTABLE CHARGER",
    image: M2A,
    properties: [
      { property: "Input", value: "220V+20%" },
      { property: "Frequency", value: "50HZ | 60HZ" },
      { property: "Output voltage", value: "3.5 KW/16A | 7kw /32A" },
      { property: "Output current", value: "8/10/13/16A | 8/10/13/16/32A" },
      { property: "Connectors", value: "IC 62196-2(type 2)" },
      { property: "Display", value: '1.3" oiled screen' },
      {
        property: "Length of charging cable",
        value: "5m or customize length",
      },
    ],
  },
  3: {
    name: "RENOCH 60 KW DC FAST CHARGER",
    image: DCFast60,
    properties: [
      { property: "Input", value: "400VAC/480VAC(3P+N+P)" },
      { property: "Frequency", value: "50HZ | 60HZ" },
      { property: "Output voltage", value: "150 - 750VDC" },
      { property: "Output current", value: "0 - 150AMPS" },
      {
        property: "Connectors",
        value: "CCS2 | CHAdeMO | IGBT single and dual",
      },
      { property: "Power factor", value: ">0.98" },
      { property: "Altitude", value: "2000m" },
      { property: "Display", value: '7" LCD with touch screen' },
      { property: "Protective class", value: "IP54 | IK10" },
      { property: "Length of charging cable", value: "5m" },
    ],
  },
  4: {
    name: "RENOCH 30 KW DC FAST CHARGER",
    image: DCFast30,
    properties: [
      { property: "Input", value: "400VAC/480VAC(3P+N+P)" },
      { property: "Frequency", value: "50HZ I| 60HZ" },
      { property: "Output voltage", value: "150 to 750VDC" },
      { property: "Output current", value: "0 to 80A" },
      { property: "Connectors", value: "CCS2 lICHAdeMOIIGBT" },
      { property: "Power factor", value: ">0.98" },
      { property: "Altitude", value: "2000m" },
      { property: "Display", value: '7" LCD with touch screen' },
      { property: "Protective class", value: "IP54 II IK10" },
      { property: "Length of charging cable", value: "5m" },
    ],
  },
};

export const ProductCarousel = () => {
  const [index, setIndex] = useState(0);

  const handlePrevious = () => {
    setIndex(index - 1 < 0 ? 0 : index - 1);
  };
  const handleNext = () => {
    setIndex(index + 1 > 4 ? 4 : index + 1);
  };

  const activeProduct = products[index];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ position: "relative" }}
        >
          <Box
            sx={{
              position: "absolute",
              left: "-4rem",
              "@media (max-width: 600px)": {
                left: "-3rem",
              },
            }}
          >
            <IconButton onClick={handlePrevious}>
              <ArrowBackIos
                sx={{ color: index === 0 ? "transparent" : "#fff" }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              position: "absolute",
              right: "-4rem",
              "@media (max-width: 600px)": {
                right: "-3rem",
              },
            }}
          >
            <IconButton onClick={handleNext}>
              <ArrowForwardIos
                sx={{ color: index === 4 ? "transparent" : "#fff" }}
              />
            </IconButton>
          </Box>
          {[1, 2, 3, 4, 5].map((key, i) => (
            <Grid
              key={key}
              container
              sx={{
                height: "calc(530px - 64px)",
                display: index === i ? "flex" : "none",
              }}
            >
              <Grid item xs={12} sm={6}>
                <img
                  src={activeProduct.image}
                  alt="Product"
                  style={{ maxWidth: "70%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={styles.container}>
                  <Typography variant="h6" sx={styles.heading}>
                    {activeProduct.name}
                  </Typography>
                  {activeProduct.properties.map(({ property, value }) => (
                    <Property property={property} value={value} />
                  ))}
                </Box>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const Property = ({ property, value }: { property: string; value: string }) => (
  <Box sx={styles.propertyWrapper}>
    <Typography variant="caption" sx={styles.property}>
      {property}
    </Typography>
    <Typography variant="caption" sx={styles.value}>
      {value}
    </Typography>
  </Box>
);
