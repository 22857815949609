import * as React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import SectionBg from "../assets/products-bg.png";
import { ProductCarousel } from "../components/product-carousel";

const Section = styled(Box)(({ theme }) => ({
  height: "48rem",
  backgroundImage: `url(${SectionBg})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  alignItems: "center",
  padding: "2rem 10vw",
  overflow: "auto",
  maxHeight: "100%",
  [theme.breakpoints.down("sm")]: {
    height: "73rem",
    display: "block",
  },
}));

export const ProductsSection = () => {
  return (
    <Section>
      <Typography variant="h6" py={2} sx={{ color: "#fff" }}>
        Products
      </Typography>
      <ProductCarousel />
    </Section>
  );
};
