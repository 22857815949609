import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import SectionBg from "../assets/subheader-bg.png";

const Section = styled(Box)(({ theme }) => ({
  height: "25rem",
  backgroundImage: `url(${SectionBg})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 10vw",
  [theme.breakpoints.down("sm")]: {
    height: "16rem",
  },
}));

export const SubHeaderSection = () => {
  return (
    <Section>
      <Typography variant="h6" sx={{ color: "#fff" }}>
        The Auto Supply Experts
      </Typography>
      <Typography variant="body1" sx={{ color: "#fff" }}>
        Renoch Charging Technology is building the eco mobility infrastructure
        and EV Revolution in India. Renoch is providing all type of home
        charging and public sector charging. We are installing the AC and DC
        fast EV charging stations at commercial locations, shopping malls,
        cinema malls, Hospitals,company campus, public car parking areas,
        residential areas.
      </Typography>
    </Section>
  );
};
