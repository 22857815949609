import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { CoreValue } from "../components";
import { styled } from "@mui/material/styles";
import SolarPanel from "../assets/solar-panel.png";

const coreValues = [
  "Safety",
  "Quality",
  "Customer Success",
  "Business Development",
  "Reliability",
];

const Container = styled(Grid)(({ theme }) => ({
  backgroundImage: `url('${SolarPanel}')`,
  backgroundPosition: "bottom right",
  backgroundRepeat: "no-repeat",
  backgroundSize: "auto 60%",
  // backgroundSize: "cover",
  [theme.breakpoints.down("sm")]: {
    backgroundSize: "0%",
  },
}));

const Content = styled(Box)(({ theme }) => ({
  padding: "0 3vw",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const AboutSection = () => {
  return (
    <Grid sx={{ backgroundColor: "#6BC9A3" }}>
      <Container container spacing={3} sx={{ color: "#fff", p: 3 }}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" color="inherit" fontWeight="bold">
            Vision
          </Typography>
          <Content>
            <Typography variant="body1" color="inherit">
              We are focusing to develop the business in renewable energy
              environment with a clear objective for reducing the carbon dioxide
              footprint in the atmosphere. We are come out with all our nature
              friendly quality products and services, that we can present and
              innovate with our advanced technology quality through out all the
              excellence services and customer satisfactions. We make a positive
              change in society by providing clean and self-sustainable
              -Mobility solutions with our cost-effective, reliable and
              high-quality services in EV charging.
            </Typography>
          </Content>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" color="inherit" fontWeight="bold">
            Mission
          </Typography>
          <Content>
            <Typography variant="body1" color="inherit">
              We are focusing to develop the business in renewable energy
              environment with a clear objective for reducing the carbon dioxide
              footprint in the atmosphere. We are come out with all our nature
              friendly quality products and services, that we can present and
              innovate with our advanced technology quality through out all the
              excellence services and customer satisfactions. We make a positive
              change in society by providing clean and self-sustainable
              -Mobility solutions with our cost-effective, reliable and
              high-quality services in EV charging.
            </Typography>
          </Content>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" color="inherit" fontWeight="bold">
            Core Values
          </Typography>
          <Content>
            {coreValues.map((value) => (
              <CoreValue key={value} text={value} />
            ))}
          </Content>
        </Grid>
      </Container>
    </Grid>
  );
};
