import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import LandingBg from "../assets/landing-bg.png";
import { Grid, Typography } from "@mui/material";
import GasStation from "../assets/gas-station-header.png";
import { Navbar } from "../components";

const Section = styled(Box)(({ theme }) => ({
  // height: "40rem",
  backgroundImage: `url(${LandingBg})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
}));

const TitleBox = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.primary.main,
  padding: "64px",
  height: "100%",
  display: "flex", // Set display to flex
  alignItems: "center", // Vertically center the content
}));

export const HeaderSection = () => {
  return (
    <Section>
      <Grid container>
        <Grid item xs={12}>
          <Navbar />
        </Grid>
        <Grid
          container
          sx={{ minHeight: "calc(530px - 64px)", marginTop: "4rem" }}
        >
          <Grid item xs={12} sm={6}>
            <TitleBox>
              <Box>
                <Typography variant="h4" sx={{ color: "#fff" }}>
                  Welcome to Renoch
                </Typography>
                <Typography variant="body1" sx={{ color: "#fff" }}>
                  Your one-stop EV charging solution We provide a reliable and
                  convenient charging solution for electric vehicle owners. Our
                  sustainable and eco-friendly approach supports the use of
                  electric vehicles and helps reduce carbon emissions. With a
                  focus on professionalism and customer satisfaction, we are
                  committed to providing exceptional charging services to the
                  electric vehicle community.
                </Typography>
              </Box>
            </TitleBox>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              // height: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img
              src={GasStation}
              alt="Gas Station"
              style={{
                maxWidth: "70%",
                // maxHeight: "40vh",
                maxHeight: "-webkit-fill-available",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
};
