import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Grid, Hidden, Typography } from "@mui/material";
import RenwableEnergy from "../assets/renwable-energy.png";
import RenwableBg from "../assets/renewable-energy-bg.png";

const Section = styled(Box)(({ theme }) => ({
  // height: "530px",
  backgroundImage: `url(${RenwableBg})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    height: "200px",
  },
}));

const TitleBox = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.primary.main,
  padding: "64px",
  height: "100%",
  display: "flex", // Set display to flex
  alignItems: "center", // Vertically center the content
}));

const Container = styled(Grid)(({ theme }) => ({
  height: "30rem",
  [theme.breakpoints.down("sm")]: {
    height: "auto",
  },
}));

export const RenewableEnergySection = () => {
  return (
    <Section>
      <Container container sx={{ height: "30rem" }}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ alignItems: "center", display: "flex" }}
        >
          <Hidden smDown>
            <img
              src={RenwableEnergy}
              alt="Renewable"
              style={{ maxWidth: "70%" }}
            />
          </Hidden>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            height: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            display: "flex",
          }}
        >
          <TitleBox>
            <Box>
              <Typography variant="h6" sx={{ color: "#fff" }} textAlign="right">
                Charging the Future
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#fff" }}
                textAlign="right"
              >
                We are committed to supporting renewable energy solutions in
                order to promote a sustainable and eco-friendly future. We
                understand that electric vehicles are a key component of this
                vision, and we strive to provide reliable and convenient
                charging services that are powered by renewable energy sources.
              </Typography>
            </Box>
          </TitleBox>
        </Grid>
      </Container>
    </Section>
  );
};
