import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(30 150 82)", // A shade of green
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h4: {
      fontSize: "2.125rem",
      // fontWeight: 500,
      // lineHeight: 1.2,
      // letterSpacing: "-0.01562em",
      "@media (max-width:960px)": {
        fontSize: "2rem",
      },
      "@media (max-width:768px)": {
        fontSize: "1.8rem",
      },
      "@media (max-width:600px)": {
        fontSize: "1.5rem",
      },
    },
    h6: {
      fontSize: "1.5rem",
      "@media (max-width:960px)": {
        fontSize: "1.2rem",
      },
      "@media (max-width:768px)": {
        fontSize: "1rem !important",
      },
      "@media (max-width:600px)": {
        fontSize: "0.8rem",
      },
    },
    body1: {
      fontSize: "1rem",
      "@media (max-width:960px)": {
        fontSize: "0.8rem",
      },
      "@media (max-width:768px)": {
        fontSize: "0.6rem !important",
      },
      "@media (max-width:600px)": {
        fontSize: "0.4rem",
      },
    },
    caption: {
      fontSize: "0.75rem",
      "@media (max-width:960px)": {
        fontSize: "0.65rem",
      },
      "@media (max-width:768px)": {
        fontSize: "0.65rem !important",
      },
    },
  },
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default responsiveFontSizes(theme);
