import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { styled } from "@mui/material/styles";
import { Button, Hidden, IconButton, Typography } from "@mui/material";
import LogoCircle from "../assets/logo-circle.png";

const Logo = styled("img")(({ theme }) => ({
  height: "3.125rem",
  border: "2px solid white",
  borderRadius: "1.57rem",
  [theme.breakpoints.down("sm")]: {
    height: "2.5rem",
  },
}));

const NavbarButton = styled(Button)(({ theme }) => ({
  color: "white",
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 500,
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

export const Navbar = () => {
  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#3cb583" }}>
      <Toolbar>
        <Logo src={LogoCircle} alt="Logo" />{" "}
        <Hidden smUp>
          {" "}
          <Typography variant="h6" ml={1} sx={{ color: "#fff" }}>
            RENOCH
          </Typography>
        </Hidden>
        <div style={{ flexGrow: 1 }} />
        <Hidden smDown>
          <NavbarButton startIcon={<MailIcon />} href="mailto:sales@renoch.in">
            Email
          </NavbarButton>
          <NavbarButton startIcon={<PhoneIcon />} href="tel:+917899567358">
            Call
          </NavbarButton>
          <NavbarButton
            startIcon={<WhatsAppIcon />}
            href="https://wa.me/7899567358"
          >
            WhatsApp
          </NavbarButton>
        </Hidden>
        <Hidden smUp>
          <IconButton color="primary" href="mailto:sales@renoch.in">
            <MailIcon style={{ color: "white" }} />
          </IconButton>
          <IconButton color="primary" href="tel:+917899567358">
            <PhoneIcon style={{ color: "white" }} />
          </IconButton>
          <IconButton color="primary" href="https://wa.me/7899567358">
            <WhatsAppIcon style={{ color: "white" }} />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};
