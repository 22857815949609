import { Hidden, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";

export const CoreValue = ({ text }: { text: string }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
      <Hidden smDown>
        <CheckCircleIcon style={{ marginRight: 8 }} />
      </Hidden>
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
};
