import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Grid, IconButton, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Logo from "../assets/logo-transparent.png";
import Toolbar from "@mui/material/Toolbar";

const Section = styled(Box)(({ theme }) => ({
  // height: "530px",
  backgroundColor: `#242424`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "0 6vw",
  // height: "30rem",
}));

const styles = {
  container: {
    border: "2px solid #6BC9A3",
    backgroundColor: "#242424",
    padding: "20px",
    borderRadius: "7px",
  },
  heading: {
    textAlign: "center",
    color: "#6BC9A3",
    marginBottom: "20px",
    textTransform: "uppercase",
  },
  propertyWrapper: {
    color: "#b0b0b0",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  property: {
    color: "#b0b0b0",
  },
  value: {
    color: "#ffffff",
    textAlign: "left",
  },
  boldLink: {
    color: "white",
    fontWeight: "bold",
    textDecoration: "none",
  },
};

export const FooterSection = () => {
  return (
    <>
      <Section>
        <Grid container spacing={2} my={3} sx={{ width: "80vw" }}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1" sx={{ color: "#fff" }}>
              <Box sx={styles.propertyWrapper}>
                <Typography variant="caption" sx={styles.property}>
                  Address
                </Typography>
                <Typography variant="caption" sx={styles.value}>
                  First floor, V4 Central Square,
                  <br /> Near V4 Central Township, <br />
                  Kukkikatte Junction, <br />
                  Udupi Town - 543210
                </Typography>
              </Box>
              {/*<Box sx={styles.propertyWrapper}>*/}
              {/*  <Typography variant="caption" sx={styles.property}>*/}
              {/*    Mumbai Branch Address*/}
              {/*  </Typography>*/}
              {/*  <Typography variant="caption" sx={styles.value}>*/}
              {/*    104, First floor,*/}
              {/*    <br /> Thakker heights Bandup west,*/}
              {/*    <br /> Mumbai*/}
              {/*  </Typography>*/}
              {/*</Box>*/}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Box sx={styles.propertyWrapper}>
              <Typography variant="caption" sx={styles.property}>
                Email
              </Typography>
              <Typography variant="caption" sx={styles.value}>
                sales@renoch.in
              </Typography>
            </Box>
            <Box sx={styles.propertyWrapper}>
              <Typography variant="caption" sx={styles.property}>
                Phone
              </Typography>
              <Typography variant="caption" sx={styles.value}>
                +91 789 956 7358
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography
              variant="body1"
              textAlign="left"
              sx={{ color: "#fff", marginBottom: "0.5rem" }}
            >
              <img src={Logo} alt="Logo" style={{ maxHeight: "0.9rem" }} />{" "}
              RENOCH
            </Typography>
            <Box sx={styles.propertyWrapper}>
              <Typography variant="caption" sx={styles.property}>
                Social
              </Typography>
              <Toolbar
                sx={{ margin: 0, padding: 0, paddingLeft: "0 !important" }}
              >
                <IconButton
                  color="inherit"
                  href="https://instagram.com/renoch_charging_technology?igshid=YmMyMTA2M2Y="
                >
                  <InstagramIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  href="https://www.facebook.com/profile.php?id=100089071118716&mibextid=ZbWKwL"
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  href="https://www.linkedin.com/in/renoch-charging-technology-b89666260"
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton color="inherit" href="https://wa.me/7899567358">
                  <WhatsAppIcon />
                </IconButton>
              </Toolbar>
            </Box>
          </Grid>
        </Grid>
      </Section>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "#999999",
          borderTop: "2px solid #6BC9A3",
          backgroundColor: "#242424",
          padding: "8px 6vw",
        }}
      >
        <Typography variant="caption">
          © {new Date().getFullYear()} - RENOCH CHARGING TECHNOLOGY
        </Typography>
        <Typography variant="caption">
          Developed By{" "}
          <a href="https://m.me/dsouzaedison" style={styles.boldLink}>
            {" "}
            Edison
          </a>
        </Typography>
      </Grid>
    </>
  );
};
