import * as React from "react";
import { HeaderSection } from "./header.section";
import { SubHeaderSection } from "./sub-header.section";
import { AboutSection } from "./about.section";
import { ProductsSection } from "./products.section";
import { RenewableEnergySection } from "./renewable-energy.section";
import { TestimonialsSection } from "./testimonials.section";
import { LocationSection } from "./locations.section";
import { FooterSection } from "./footer.section";

export const LandingPage = () => {
  return (
    <>
      <HeaderSection />
      <SubHeaderSection />
      <AboutSection />
      <ProductsSection />
      <RenewableEnergySection />
      <TestimonialsSection />
      <LocationSection />
      <FooterSection />
    </>
  );
};
