import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Grid, Tab, Tabs, Typography } from "@mui/material";

const maps = [
  // Udupi
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3882.0180160820537!2d74.734692176113!3d13.349155387002105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbcbb7e57c04017%3A0x38db894ddc587258!2sSHARADA%20INTERNATIONAL!5e0!3m2!1sen!2sin!4v1682948848274!5m2!1sen!2sin",
  // Katpadi
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d36940.79320505375!2d74.71400345943094!3d13.29277709984255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbcbb90249ea275%3A0x98f48cab47f748b8!2sKaustubha%20Residency!5e0!3m2!1sen!2sin!4v1706102786454!5m2!1sen!2sin",
  // Office
  "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2357.0383059813275!2d74.76668956661109!3d13.321052234201924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDE5JzE3LjQiTiA3NMKwNDYnMDkuNiJF!5e0!3m2!1sen!2sin!4v1682949336512!5m2!1sen!2sin",
];

const Section = styled(Box)(({ theme }) => ({
  height: "530px",
  backgroundColor: "#343434",
  display: "flex",
  flexDirection: "column",
  padding: "3rem 0",
  // justifyContent: "center",
  // height: "30rem",
}));

export const LocationSection = () => {
  const [value, setValue] = React.useState(2);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Section>
      <Typography variant="h6" sx={{ color: "#fff" }}>
        Location
      </Typography>
      <Grid container justifyContent="center">
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Udupi" sx={{ color: "#fff" }} />
          <Tab label="Katpadi" sx={{ color: "#fff" }} />
          <Tab label="Office" sx={{ color: "#fff" }} />
        </Tabs>
      </Grid>
      <Grid container>
        <Grid item xs={12} mt={2}>
          <iframe
            title="Maps"
            src={maps[value]}
            width="100%"
            style={{ border: 0, height: "24rem" }}
            allowFullScreen
            loading="lazy"
          ></iframe>
        </Grid>
      </Grid>
    </Section>
  );
};
