import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import TestimonialsBg from "../assets/testimonials-bg.png";
import TestimonialWrapper from "../assets/testimonial-wrapper.png";

const Section = styled(Box)(({ theme }) => ({
  // height: "530px",
  backgroundImage: `url(${TestimonialsBg})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  display: "flex",
  flexDirection: "column",
  padding: "3rem 0",
  justifyContent: "center",
  // height: "30rem",
}));
const Testimonial = styled(Box)(({ theme }) => ({
  // height: "530px",
  backgroundImage: `url(${TestimonialWrapper})`,
  // minHeight: "22rem",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  padding: "5rem",
  backgroundSize: "80% 100%",
}));
const TestimonialText = styled(Typography)(({ theme }) => ({
  border: "1px solid #fff",
  borderRadius: "5px",
  padding: "1rem",
  maxWidth: "70%",
  margin: "0 auto",
}));

export const TestimonialsSection = () => {
  return (
    <Section>
      <Typography variant="h6" sx={{ color: "#fff" }}>
        Testimonials
      </Typography>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{ alignItems: "center", display: "flex" }}
        >
          <Testimonial>
            <TestimonialText variant="body1" sx={{ color: "#fff" }}>
              Fast and easy charging, well-maintained station, will be back for
              future charging needs.
            </TestimonialText>
          </Testimonial>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{ alignItems: "center", display: "flex" }}
        >
          <Testimonial>
            <TestimonialText variant="body1" sx={{ color: "#fff" }}>
              Straightforward process, impressive charging speed, reliable
              station for daily commute.
            </TestimonialText>
          </Testimonial>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{ alignItems: "center", display: "flex" }}
        >
          <Testimonial>
            <TestimonialText variant="body2" sx={{ color: "#fff" }}>
              Consistently fast charging, clean and well-maintained station,
              reliable service whenever needed.
            </TestimonialText>
          </Testimonial>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            height: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            display: "flex",
          }}
        ></Grid>
      </Grid>
    </Section>
  );
};
